<template>
  <div class="app-login">
    <div class="app-logo">
      <div class="app-logo-text">
        <img src="@/assets/spec_logo.png" alt style="width:60px;margin-right: 10px" />
        <span>TECH DOC. EDITOR Manager</span>
      </div>
      <div class="app-logo-dec">TECH DOC. EDITOR Import System Manager</div>
    </div>

    <div class="app-login-handle">
      <div style="margin-bottom: 10px;">
        <el-input
          v-model="username"
          placeholder="请输入用户名"
          style="width: 500px;"
          prefix-icon="el-icon-user"
        ></el-input>
      </div>
      <div>
        <el-input
          type="password"
          v-model="password"
          placeholder="请输入密码"
          style="width: 500px;"
          prefix-icon="el-icon-lock"
          @keyup.enter.native="handleClick"
        ></el-input>
      </div>

      <div style="margin-top: 100px;">
        <el-button
          @click="handleClick"
          style="background:#1890FF;width: 500px;height: 50px;color: #fff;"
        >{{loginText()}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "login",
    data() {
      return {
        username: "",
        password: "",
        isLogin: false
      };
    },

    methods: {
      handleClick() {
        if (this.isLogin) {
          return;
        }
        if (!this.username) {
          this.$message({ type: "error", message: "Please Input Username" });
          return;
        }
        if (!this.password) {
          this.$message({ type: "error", message: "Please Input Password" });
          return;
        }
        this.isLogin = true;
        let params = { username: this.username, password: this.password };
        this.$api
          .login(params)
          .then(data => {
            let userVo = data.loginSysUserVo
            if(userVo.username=='Kawa_Kwok' || userVo.username=='Michael_Tang' || userVo.username=='George_Wong'){
              userVo.operateStatus = false
            }
            else{
              userVo.operateStatus = true
            }
            this.$message({ type: "success", message: "Login Successfully" });
            localStorage.setItem("specToken", data.token);
            localStorage.setItem("specUserInfo", JSON.stringify(userVo));
            const isDomainValid = window.location.hostname === "cn.spec.dracoherbs.com";
      
            let path = "/home"
            if(isDomainValid){
              path = "/cn-home"
            }
         
            this.$router.push({
              path:path  //如果存在query 就进入query存储的页面 如果没有进入首页
            })
            this.isLogin = false;
          })
          .catch(err => {
            this.isLogin = false;
            console.log(err);
          });
      },
      loginText() {
        return this.isLogin ? "Be Logining in..." : "Login";
      }
    },
    created() {
      var query = this.$route.query;
      var param = query.params;
      if(param){
        // let p ={username:"xxx",password:"xxxxxx"}
        // let p1= JSON.stringify(p)
        // let pp = encodeURIComponent(btoa(p1))
        // console.log(pp)
        var j1 = decodeURIComponent(atob(param));
        var j2 = j1.substring(0,3)+j1.substring(6,j1.length);
        var j3 = j2.substring(0,j2.length-8)+j2.substring(j2.length-3,j2.length);
        var res = decodeURIComponent(atob(j3));
        var json=JSON.parse(res);
        this.username=json.username;
        this.password=json.password;
        this.handleClick();
      }
      
    }
  };
</script>

<style lang="scss" scoped>
  .app-login {
    background: url("../assets/login_bgc.svg") no-repeat center;
    background-size: 92%;
    width: 100%;
    height: 100%;
    .app-logo {
      height: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .app-logo-text {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #212327;
        font-size: 44px;
      }
      .app-logo-dec {
        margin-top: 20px;
        color: #9b9ca3;
        font-size: 20px;
      }
    }
    .app-login-handle {
      ::v-deep .el-input__inner {
        height: 50px;
      }
    }
  }
</style>